import type { LoaderFunctionArgs } from '@remix-run/node';
import { Link, type MetaFunction, useLoaderData, useSearchParams } from '@remix-run/react';
import { ActivityCard } from '~/components/ActivityCard';
import { ArrowBack } from '~/components/icons/ArrowBack';
import { buttonVariants } from '~/components/ui/Button';
import { Image } from '~/components/ui/Image';
import { Pagination, PaginationContent, PaginationItems } from '~/components/ui/Pagination';
import { HeadingXL, HeadingXXL, headingVariants, textVariants } from '~/components/ui/Typography';
import { activitiesQuery } from '~/generated/server';
import { useTranslation } from '~/i18n';
import { requireViewerSession } from '~/utils/auth.server';
import { cn } from '~/utils/cn';
import { connectionResultToNodeArray } from '~/utils/connection-result-to-node-array';
import { mergeMeta } from '~/utils/merge-meta';
import { getMeta } from '~/utils/meta';
import { computePage } from '~/utils/pagination.server';
import { SearchParams } from '~/utils/search-params';

export const handle = {
  i18n: 'my-lunches',
};

export const meta: MetaFunction = mergeMeta(() =>
  getMeta([
    {
      title: 'Mes déjeuners - Club Colette',
    },
    {
      name: 'description',
      content:
        'Retrouvez les déjeuners auxquelles vous êtes inscrit(e) | Accès gratuit et sans engagement | Faites des rencontres grâce au Club Colette',
    },
  ]),
);

export async function loader(ctx: LoaderFunctionArgs) {
  await requireViewerSession(ctx);

  const sp = new SearchParams<{ tab?: 'PAST' }>(ctx.request.url);

  const response = await activitiesQuery(ctx, {
    ...computePage(ctx.request, 12),
    filter: {
      registeredTo: true,
      types: ['LUNCH'],
      upcomingOrOngoing: sp.get('tab') !== 'PAST',
    },
  });

  return {
    count: response.activities.count || 0,
    lunches: connectionResultToNodeArray(response.activities),
  };
}

export default function MyLunchesRoute() {
  const data = useLoaderData<typeof loader>();
  const { t } = useTranslation(handle.i18n);
  const [sp] = useSearchParams();

  const tab = sp.get('tab');

  return (
    <div className="mx-auto max-w-screen-xl space-y-8 px-4 py-12 lg:px-0">
      <div className="space-y-4">
        <Link
          prefetch="intent"
          to="/"
          className={textVariants({
            size: 'm',
            class: 'flex items-center font-bold',
          })}
        >
          <ArrowBack className="mr-4 size-5 text-subdued-foreground" />
          {t('home')}
        </Link>
        <HeadingXXL>{t('my-lunches')}</HeadingXXL>
      </div>

      <ul
        className={headingVariants({
          size: 's',
          class: 'flex items-center gap-2 border-b-2 text-subdued-foreground',
        })}
      >
        <li
          className={cn('p-2', {
            'border-b-2 border-foreground font-bold text-foreground': tab !== 'PAST',
          })}
        >
          <Link to="" prefetch="intent">
            {t('upcoming')}
          </Link>
        </li>
        <li
          className={cn('p-2', {
            'border-b-2 border-foreground font-bold text-foreground': tab === 'PAST',
          })}
        >
          <Link to="?tab=PAST" prefetch="intent">
            {t('past')}
          </Link>
        </li>
      </ul>

      {data.lunches?.length ? (
        <>
          <div className="my-10 grid grid-cols-[repeat(auto-fill,_minmax(300px,_1fr))] gap-4 lg:gap-6">
            {data.lunches.map((lunch) => (
              <ActivityCard key={lunch.slug} activity={lunch} />
            ))}
          </div>

          <Pagination>
            <PaginationContent>
              <PaginationItems perPage={12} count={data.count || 0} />
            </PaginationContent>
          </Pagination>
        </>
      ) : (
        <EmptyLunchState />
      )}
    </div>
  );
}

export function EmptyLunchState() {
  const { t } = useTranslation(handle.i18n);

  return (
    <div className="bg-surface-dark rounded-xl grid lg:grid-cols-[1fr_36%]">
      <div className="p-10 text-on">
        <HeadingXL className="mb-6">
          {t('meet-members-near-you-thanks-to-colette-lunches')}
        </HeadingXL>

        <p className={textVariants({ size: 'xl', class: 'mb-8 font-medium' })}>
          {t('meet-members-near-you-thanks-to-colette-lunches-description')}
        </p>

        <Link
          to="/dejeuners-senior/reserve"
          className={buttonVariants({ class: 'w-full lg:w-auto' })}
        >
          {t('register-to-a-lunch')}
        </Link>
      </div>

      <Image
        alt="Un groupe de seniors souriants réunis autour d'une table, partageant des rires et des boissons dans un cadre chaleureux."
        src="/images/home-cover.jpeg"
        className="h-full object-cover object-[85%_center] rounded-r-xl hidden lg:block"
        width={1200}
        height={800}
        quality={85}
      />
    </div>
  );
}
